<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
    light
  >
    <v-list>
      <v-list-tile
        v-for="(link, i) in links"
        :key="i"
        :to="link[0]"
      >
        <v-list-tile-title v-text="link[1]" />
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapMutations,
    mapState
  } from 'vuex'

  export default {
    computed: {
      ...mapState('app', ['links']),
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        }
      }
    },

    methods: {
      ...mapMutations('app', ['setDrawer'])
    }
  }
</script>
